<template>
  <div class="collection-details">
    <headinformation />
    <div class="details-head-text">{{ this.$route.query.name }}</div>
    <div class="details-head">
      <div class="guanzhu">我的关注</div>
      <div class="details-head-box">
        <div class="details-head-box-box">
          <div class="details-head-box-box-input">
            <el-input
              v-model="input"
              placeholder="请输入内容"
              @change="change"
            ></el-input>
          </div>
          <el-button
            type="success"
            style="background: #0e9ba9; color: #fff; border-color: #0e9ba9"
            @click="searchExpert"
            >搜索</el-button
          >
        </div>
        <el-button
          type="success"
          style="
            background: #2563eb;
            color: #fff;
            border-color: #2563eb;
            margin-left: 16px;
          "
          @click="$router.back()"
          >返回</el-button
        >
      </div>
    </div>
    <div class="foreinger">
      <ul class="foreinger-list" v-if="aprenderObject.list.length != 0">
        <li
          class="foreinger-list-item"
          v-for="(item, index) in aprenderObject.list"
          :key="item.id"
          @click="todetail(item)"
        >
          <div class="foreinger-list-item-head-right">
            <div class="foreinger-list-item-head-right-lv">
              <!-- {{ item.tags.split(",")[0] }} -->
              ASCO讲者
            </div>
            <div class="foreinger-list-item-head-right-fen">
              <!-- {{ item.tags.split(",")[1] }} -->
              顶刊主编
            </div>
            <div class="foreinger-list-item-head-right-lan">
              <!-- {{ item.tags.split(",")[2] }} -->
              诺贝尔奖获得者
            </div>
            <div class="foreinger-list-item-head-right-img">
              <img src="@/assets/image/foreignnationals/dou.png" />
            </div>
          </div>
          <!-- //1 -->
          <div class="foreinger-list-item-aaaaa">
            <div class="foreinger-list-item-heda">
              <img :src="item.photo" />
            </div>
            <!-- //1 -->
            <!-- //2 -->
            <div class="foreinger-list-item-box">
              <!-- //3 -->
              <div class="foreinger-list-item-title">
                <div class="foreinger-list-item-name">
                  {{ item.name }}
                </div>
                <div class="foreinger-list-item-country">
                  <span class="foreinger-list-item-country1"> 国家：</span>
                  <span class="foreinger-list-item-country2">
                    {{ item.country }}
                  </span>
                </div>
              </div>
              <!-- //3 -->
              <!-- //4 -->
              <div class="foreinger-list-item-contents">
                <!-- //5 -->
                <div class="foreinger-list-item-contents-left">
                  <!-- 上 -->
                  <div class="foreinger-list-item-contents-left-appointments">
                    <div
                      class="foreinger-list-item-contents-left-appointments-box"
                    >
                      <div
                        class="foreinger-list-item-contents-left-general-w"
                      ></div>
                      <div class="foreinger-list-item-contents-left-general-t">
                        就职机构及任职：
                      </div>
                    </div>
                    <div class="foreinger-list-item-contents-left-position">
                      {{ item.institution }}
                    </div>
                  </div>
                  <!-- 上 结束-->
                  <!-- 下 -->
                  <div class="foreinger-list-item-contents-left-appointments">
                    <div
                      class="foreinger-list-item-contents-left-appointments-box"
                    >
                      <div
                        class="foreinger-list-item-contents-left-general-w"
                      ></div>
                      <div class="foreinger-list-item-contents-left-general-t">
                        擅长领域：
                      </div>
                    </div>
                    <div class="foreinger-list-item-contents-left-position-x">
                      {{ item.areas_of_expertise }}
                    </div>
                  </div>
                  <!-- 下 结束-->
                </div>
                <!-- //5 -->
                <!-- //6 -->
                <div class="foreinger-list-item-contents-right">
                  <div
                    class="foreinger-list-item-contents-right-box"
                    @click.stop.prevent="todetail(item)"
                  >
                    <div class="foreinger-list-item-contents-right-box-img">
                      <img
                        src="@/assets/image/foreignnationals/xiangqing.png"
                      />
                    </div>
                    <div class="foreinger-list-item-contents-right-box-text">
                      详情
                    </div>
                  </div>
                  <!-- 关注 取消关注 -->
                  <div @click.stop.prevent="expertFollowClick(item)">
                    <div
                      class="foreinger-list-item-contents-right-box"
                      v-if="item.is_follow == 0"
                    >
                      <div class="foreinger-list-item-contents-right-box-img">
                        <img
                          src="@/assets/image/foreignnationals/guanzhu.png"
                        />
                      </div>
                      <div class="foreinger-list-item-contents-right-box-text">
                        关注
                      </div>
                    </div>

                    <div
                      class="foreinger-list-item-contents-right-box"
                      v-if="item.is_follow == 1"
                    >
                      <div class="foreinger-list-item-contents-right-box-img">
                        <img
                          src="@/assets/image/foreignnationals/quxiaoguanzhu.png"
                        />
                      </div>
                      <div class="foreinger-list-item-contents-right-box-text">
                        取消关注
                      </div>
                    </div>
                  </div>
                </div>
                <!-- //6 -->
              </div>
              <!-- //4 -->
            </div>
          </div>
          <!-- //2 -->
        </li>
      </ul>
      <div v-else class="zanwushuju">暂无数据</div>
    </div>
    <div class="page" v-show="aprenderObject.list">
      <el-pagination
        layout="prev, pager, next"
        @current-change="currentchange"
        @prev-click="prevClick"
        @next-click="nextClick"
        :current-page.sync="currentPage"
        :page-count="aprenderObject.pages"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import headinformation from "@/components/headinformation/index.vue";
export default {
  name: "foreignSpeakers",
  components: {
    headinformation,
  },
  data() {
    return {
      recommend_id: "",
      argument: "",
      dialogVisible: false,
      recommendText: "",
      input: "",
      userinfo: {},
      //外籍讲者
      total: 10, //总条目数
      pagesize: 8, //每页显示条目个数
      currentPage: 1, //当前页数
      aprenderObject: { list: [] },
      aaaaaa: [],
    };
  },
  created() {
    this.getForeignSpeakerList();
  },
  methods: {
    //列表
    async getForeignSpeakerList() {
      const { data: data } = await this.$http.post("auth/follow", {
        name: this.input,
        page: this.currentPage,
        limit: this.pagesize,
      });
      this.aprenderObject = data.data;
      this.$Spin.hide();
    },
    //点击搜索
    searchExpert() {
      this.currentPage = 1;
      setTimeout(() => {
        this.getForeignSpeakerList();
      }, 500);
      this.$Spin.show();
    },
    //回车搜索
    change(value) {
      this.input = value;
      this.currentPage = 1;
      setTimeout(() => {
        this.getForeignSpeakerList();
      }, 500);
      this.$Spin.show();
    },
    // 外籍讲者详情
    todetail(item) {
      this.$router.push({
        path: "/homeDetail",
        query: {
          id: item.id,
        },
      });
    },
    //关注 取消关注 点击
    expertFollowClick(item) {
      let status = 0;
      if (item.is_follow == 0) {
        status = 1;
      }
      if (item.is_follow == 1) {
        status = 0;
      }
      if (status == 0) {
        this.$confirm("您是否要取消关注？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(async () => {
          this.getuserFollow(item.id, status);
        });
      } else {
        this.getuserFollow(item.id, status);
      }
    },
    //关注 取消关注 接口
    async getuserFollow(info_id, status) {
      const { data: data } = await this.$http.post("speaker/follow", {
        info_id,
        type_id: 8,
        status,
      });
      this.getForeignSpeakerList();
      this.$message({
        message: data.message,
        type: "success",
      });
    },
    //点击每一页
    currentchange(e) {
      this.currentPage = e;
      // document.querySelector(".el-main").scrollTop = 0;
      setTimeout(() => {
        this.getForeignSpeakerList();
      }, 500);
      this.$Spin.show();
    },
    //上一页
    prevClick(e) {
      this.currentPage = e;
      // document.querySelector(".el-main").scrollTop = 0;
      setTimeout(() => {
        this.getForeignSpeakerList();
      }, 500);
      this.$Spin.show();
    },
    //下一页
    nextClick(e) {
      this.currentPage = e;
      // document.querySelector(".el-main").scrollTop = 0;
      setTimeout(() => {
        this.getForeignSpeakerList();
      }, 500);
      this.$Spin.show();
    },
  },
};
</script>
<style lang="less" scoped>
.collection-details {
  position: relative;
  min-height: 100vh;
  background-color: #f2f3f5 !important;

  .foreinger {
    margin: 0 50px;
    // background-color: #fff;
    .foreinger-list {
      min-height: 665px;
      .foreinger-list-item {
        margin-bottom: 10px;
        // padding: 17px 0 22px 19px;
        padding: 34px 42px 22px 44px;
        background-color: #fff;
        cursor: pointer;
        // display: flex;
        &:last-child {
          margin-bottom: 0px;
        }

        /deep/ .el-checkbox__label {
          width: 100%;
          margin-top: 20px;
          padding-left: 0px;
          display: flex;
        }
        .foreinger-list-item-heda {
          width: 61px;
          height: 61px;
          border-radius: 50%;
          overflow: hidden;
          margin-right: 19px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .foreinger-list-item-box {
          flex: 1;
          .foreinger-list-item-title {
            display: flex;
            align-items: center;
            margin-bottom: 33px;
            .foreinger-list-item-name {
              color: #212529;
              font-size: 18px;
              font-weight: 600;
              max-width: 985px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .foreinger-list-item-country {
              margin-left: 160px;
              .foreinger-list-item-country1 {
                color: #212529;
                font-size: 18px;
                font-weight: 600;
              }
              .foreinger-list-item-country2 {
                font-size: 13px;
                color: #212529;
              }
            }
          }

          .foreinger-list-item-contents {
            // display: flex;
            .foreinger-list-item-contents-left {
              // width: 760px;
              width: 100%;
              .foreinger-list-item-contents-left-appointments {
                .foreinger-list-item-contents-left-appointments-box {
                  display: flex;
                  align-items: center;
                  margin-bottom: 12px;
                  .foreinger-list-item-contents-left-general-w {
                    width: 10px;
                    height: 4px;
                    background-color: #4687c8;
                    margin-right: 5px;
                  }
                  .foreinger-list-item-contents-left-general-t {
                    font-size: 13px;
                    color: #212529;
                  }
                }
                .foreinger-list-item-contents-left-position {
                  font-size: 13px;
                  color: #c7c7c7;
                  margin-bottom: 27px;
                }
                .foreinger-list-item-contents-left-position-x {
                  font-size: 13px;
                  color: #c7c7c7;
                }
              }
            }
            .foreinger-list-item-contents-right {
              flex: 1;
              display: flex;
              align-self: flex-end;
              justify-content: flex-end;
              .foreinger-list-item-contents-right-box {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 40px;
                cursor: pointer;
                .foreinger-list-item-contents-right-box-text {
                  font-size: 14px;
                  color: #212529;
                  margin: 0 0 5px 5px;
                  margin-bottom: 2px;
                }
              }
            }
          }
        }
      }
    }
  }

  .dialog-recommend {
    display: flex;
    align-items: center;
    .dialog-recommend-name {
      margin-right: 10px;
      font-size: 15px;
      color: #2d2d2d;
      font-weight: 600;
      margin-left: 30px;
    }
    .dialog-recommend-select {
    }
  }

  .dialog-reason {
    margin-top: 30px;
    display: flex;
    align-items: center;
    .dialog-reason-name {
      margin-right: 10px;
      font-size: 15px;
      color: #2d2d2d;
      font-weight: 600;
    }
    .dialog-reason-input {
      width: 80%;
    }
  }

  .zan-box {
    display: flex;
    align-items: center;
    margin-right: 40px;
    margin-top: -4px;
    .zan-box-img {
      width: 18px;
      height: 18px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .zan-box-name {
      margin-left: 5px;
      margin-top: 2px;
    }
  }
}
.page {
  margin: 0 50px;
  margin-top: 16px;
  background-color: #fff;
  text-align: center;
  // padding: 5px 0;
}
.foreinger-list-item-aaaaa {
  display: flex;
  cursor: pointer;
}
.foreinger-list-item-head-right {
  position: relative;
  font-size: 13px;
  color: #212529;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .foreinger-list-item-head-right-lv {
    padding: 5px 12px;
    background-color: #bee3df;
    border-radius: 3px;
    margin-right: 20px;
  }
  .foreinger-list-item-head-right-fen {
    padding: 5px 12px;
    background-color: #f3d0d0;
    border-radius: 3px;
    margin-right: 20px;
  }
  .foreinger-list-item-head-right-lan {
    padding: 5px 12px;
    background-color: #d0e5f9;
    border-radius: 3px;
    margin-right: 36px;
  }
  .foreinger-list-item-head-right-img {
    width: 33px;
    height: 29px;
  }
}
.details-head-text {
  margin: 0 50px;
  color: #212529;
  font-style: 16px;
  font-weight: 600;
}
.details-head {
  margin: 0 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  // padding: 10px;
  margin-bottom: 10px;
  margin-top: 5px;
  .guanzhu {
    font-size: 14px;
    color: #212529;
    font-weight: 600;
  }
  .details-head-box {
    display: flex;

    .details-head-box-box {
      display: flex;
      .details-head-box-box-input {
        width: 332px;
        margin-right: 16px;
      }
    }
  }
  /deep/ .el-button {
    font-size: 12px !important;
    padding: 9px 20px !important;
  }
  /deep/ .el-input__inner {
    height: 32px !important;
    line-height: 32px !important;
  }
}
.zanwushuju {
  min-height: 585px;
  padding-top: 260px;
  text-align: center;
}
.recommend-box-on {
  .recommend-box-on-text {
    background-color: #4059fb;
    font-size: 13px;
    color: #fff;
    padding: 7px 13px;
    border-radius: 4px;
  }
}
</style>
