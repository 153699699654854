<template>
  <div class="headinformation">
    <div class="headinformation-box">
      <div class="headinformation-box-bgc" @click="$router.push('/home')">
        <img src="@/assets//image/head/head-bgc.png" />
      </div>
      <div v-if="information.realname">
        <el-popover placement="top-start" trigger="hover">
          <ul class="popover-ul">
            <li
              class="popover-li"
              v-for="(item, index) in list"
              :key="index"
              @click="popoverClick(item, index)"
            >
              <div class="popover-li-img"><img :src="item.icon" /></div>
              <div class="popover-li-name">{{ item.name }}</div>
            </li>
          </ul>

          <div class="headinformation-box-right" slot="reference">
            <div class="headinformation-box-right-name">
              {{ information.realname }}
            </div>
            <div class="headinformation-box-right-img">
              <img src="@/assets//image/head/po.png" />
            </div>
          </div>
        </el-popover>
      </div>
      <div
        v-else
        style="width: 33px; height: 33px; overflow: hidden; border-radius: 50%"
      >
        <img src="@/assets/image/head/pop.png" />
      </div>
    </div>

    <div class="headinformation-time">
      <div class="headinformation-time-box" v-if="information.realname">
        <div class="headinformation-time-box-img">
          <img src="@/assets//image/head/po.png" />
        </div>
        <div class="headinformation-time-box-name">
          {{ information.realname }}，欢迎回来！欢迎使用
          <span style="color: #0e9ba9">外讲库</span>
          !
        </div>
      </div>
      <div
        v-else
        style="z-index: 99; display: flex; align-items: center; cursor: pointer"
        @click="$router.push('/login')"
      >
        <div
          style="
            width: 63px;
            height: 63px;
            overflow: hidden;
            border-radius: 50%;
            margin-right: 15px;
          "
        >
          <img src="@/assets/image/head/pop.png" />
        </div>
        <div>您还未登录，登录查看更多内容！</div>
      </div>
      <div class="headinformation-time-left">
        <div class="headinformation-time-left-shi">{{ nowTime }}</div>
        <div class="headinformation-time-left-nian">{{ nowDate }}</div>
      </div>
      <div class="headinformation-bgc">
        <img src="@/assets/image/head/time-bgc.png" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "headinformation",
  data() {
    return {
      visible: false,
      nowDate: "",
      nowTime: "",
      information: {},
      list: [
        {
          name: "我的关注",
          icon: require("@/assets/image/head/guanzhu.png"),
        },
        {
          name: "退出登录",
          icon: require("@/assets/image/head/tuichu.png"),
        },
      ],
    };
  },
  watch: {
    $route: {
      handler: function (to, form) {
        //当没有token的时候不请求个人信息接口
        if (window.localStorage.getItem("token")) this.getMe();
      },
      // 深度观察监听
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.currentTime();
  },
  methods: {
    //获取个人信息
    async getMe() {
      const { data: data } = await this.$http.post("auth/me");
      this.information = data.data;
    },
    //获取时间
    formatDate() {
      //获取当前时间并打印
      let myDate = new Date();
      let wk = myDate.getDay();
      let yy = String(myDate.getFullYear());
      let mm = myDate.getMonth() + 1;
      let dd = String(
        myDate.getDate() < 10 ? "0" + myDate.getDate() : myDate.getDate()
      );
      let hou = String(
        myDate.getHours() < 10 ? "0" + myDate.getHours() : myDate.getHours()
      );
      let min = String(
        myDate.getMinutes() < 10
          ? "0" + myDate.getMinutes()
          : myDate.getMinutes()
      );
      let sec = String(
        myDate.getSeconds() < 10
          ? "0" + myDate.getSeconds()
          : myDate.getSeconds()
      );
      let weeks = [
        "星期日",
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六",
      ];
      let week = weeks[wk];
      this.nowDate = yy + "年" + mm + "月" + dd + "日";
      this.nowTime = hou + ":" + min + ":" + sec;
    },
    //开启定时器
    currentTime() {
      setInterval(this.formatDate, 500);
    },
    //退出 我的关注
    popoverClick(item, index) {
      if (index == 0) this.$router.push("/followDetails");
      if (index == 1) {
        this.$confirm("您是否要退出登录?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            localStorage.removeItem("token");
            localStorage.removeItem("id");
            this.$router.push("/login");
          })
          .catch(() => {});
      }
    },
  },
};
</script>
<style lang="less" scoped>
.headinformation {
  position: relative;
  padding-bottom: 10px;
  //表头
  .headinformation-box {
    background-color: #fff;
    width: 100%;
    padding: 15px 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    .headinformation-box-bgc {
      width: 117px;
      height: 38px;
      cursor: pointer;
    }

    .headinformation-box-right {
      display: flex;
      align-items: center;
      .headinformation-box-right-img {
        width: 33px;
        height: 33px;
        overflow: hidden;
        border-radius: 50%;
        // background-color: pink;
        cursor: pointer;
      }
      .headinformation-box-right-name {
        margin-right: 16px;
        font-size: 16px;
        color: #0e9ba9;
        cursor: pointer;
      }
    }
  }
  //时间
  .headinformation-time {
    margin: 0 50px;
    padding: 10px 0 10px 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    //左侧
    .headinformation-time-box {
      display: flex;
      align-items: center;
      z-index: 999;
      .headinformation-time-box-img {
        width: 55px;
        height: 55px;
        overflow: hidden;
        border-radius: 50%;
        background-color: #0e9ba9;
        margin-right: 18px;
      }
      .headinformation-time-box-name {
      }
    }
    //右侧
    .headinformation-time-left {
      z-index: 999;
      padding: 10px 36px 15px 44px;
      background-color: #2563eb;
      color: #fff;
      font-weight: 600;
      text-align: center;
      margin-right: 42px;
      .headinformation-time-left-shi {
        font-size: 39px;
        margin-bottom: 5px;
      }
      .headinformation-time-left-nian {
        font-size: 22px;
      }
    }
  }

  //背景
  .headinformation-bgc {
    margin: 0 50px;
    position: absolute;
    left: 0;
  }
}

.popover-ul {
  .popover-li {
    display: flex;
    align-items: center;
    margin: 12px 20px;
    cursor: pointer;
    &:last-child {
      margin-top: 22px;
    }
    .popover-li-img {
      width: 16px;
      height: 16px;
      margin-right: 10px;
    }
    .popover-li-name {
    }
  }
}
</style>
