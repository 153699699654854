<template>
  <div class="collection">
    <headinformation />
    <div class="collection-box">
      <div class="collection-box-head">
        <div style="color: #212529; font-size: 16px; font-weight: 600">
          我的合集
        </div>
        <div>
          <el-button
            @click="backProject"
            type="primary"
            style="background: #2563eb; color: #fff; border-color: #2563eb"
            >返回</el-button
          >
        </div>
      </div>
      <ul class="ollection-box-ul" v-if="aprenderObject.list.length != 0">
        <li
          class="ollection-box-ul-li"
          v-for="(item, index) in aprenderObject.list"
          :key="index"
          @click="collectionView(item)"
        >
          <img src="@/assets/image/collection/bgc.png" />
          <div class="ollection-box-ul-li-name">{{ item.name }}</div>
          <div class="ollection-box-ul-li-num">
            <el-button
              type="primary"
              icon="el-icon-postcard"
              style="background: #063b6b; color: #fff; border-color: #063b6b"
              >{{ item.count }}</el-button
            >
          </div>
          <div class="ollection-box-ul-li-icon" @click.stop="shareClick(item)">
            <div>
              <i style="margin-right: 5px" class="el-icon-share" />
              <span>分享</span>
            </div>
          </div>

          <div
            class="ollection-box-ul-li-icon-shan"
            @click.stop="collectionDelete(item)"
          >
            <div>
              <i style="margin-right: 5px" class="el-icon-delete" />
              <span>删除</span>
            </div>
          </div>
        </li>
      </ul>
      <div class="ollection-box-ul" v-else>
        <div style="margin: 240px auto">暂无数据</div>
      </div>
    </div>
    <div class="page" v-show="aprenderObject.list">
      <el-pagination
        layout="prev, pager, next"
        @current-change="currentchange"
        @prev-click="prevClick"
        @next-click="nextClick"
        :current-page.sync="currentPage"
        :page-count="aprenderObject.pages"
      >
      </el-pagination>
    </div>

    <!-- 分享弹窗 -->
    <el-dialog
      title="分享"
      :visible.sync="centerDialogVisible"
      width="27.5%"
      center
    >
      <div class="dialog-box">
        <div class="dialog-heda">
          <div class="dialog-heda-img">
            <img src="../../assets/image/collection/bgc.png" />
          </div>
          <div class="dialog-heda-box">
            <div class="dialog-heda-text">
              {{ CollectionTitle }}
            </div>
          </div>
        </div>

        <div class="dialog-share">
          <div class="dialog-share-fxz">发送至</div>
          <div class="dialog-share-box" style="margin-right: 20px">
            <div class="dialog-share-box-wx" @click="dialogShareWX">
              <div class="dialog-share-box-img">
                <div class="dialog-share-box-img-wx">
                  <img src="@/assets/image/collection/微信 (3).png" />
                </div>
              </div>
              <div class="dialog-share-box-text">微信好友</div>
            </div>
            <!-- 链接 -->
            <div class="dialog-share-box-lj" @click="dialogShareLJ">
              <div class="dialog-share-box-img">
                <div class="dialog-share-box-img-fx">
                  <img src="@/assets/image/collection/链接 (1).png" />
                </div>
              </div>
              <div class="dialog-share-box-text">复制链接</div>
            </div>
            <!-- 链接结束 -->
            <!-- 复制成功 -->
            <div v-if="reproductionSuccess" class="reproduction-success">
              链接复制成功
            </div>
            <!-- 复制成功 结束 -->
          </div>
        </div>
        <div class="dialog-bgc">
          <img src="@/assets/image/collection/logo.png" />
        </div>
      </div>
    </el-dialog>
    <!-- 分享弹窗 -->
    <!-- 微信扫码 弹窗 -->
    <el-dialog
      title="分享微信好友"
      :visible.sync="centerDialogVisibleWX"
      width="27.5%"
      center
    >
      <div class="dialog-box-wx">
        <div class="dialog-box-wx-img-box">
          <div class="dialog-box-wx-img">
            <!-- <img src="../../assets/image/questionnaires/下载.png"> -->
            <el-row type="flex" justify="center">
              <canvas ref="myCanvas" />
            </el-row>
          </div>
        </div>
        <div class="dialog-box-wx-text">
          <p>打开手机微信“扫一扫”，分享给好友</p>
        </div>
        <div class="dialog-bgc-wx">
          <!-- <img src="../../../assets/image/questionnaires/蒙版组 131.png" /> -->
        </div>
      </div>
    </el-dialog>
    <!-- 微信扫码 弹窗 -->
  </div>
</template>
<script>
import QrCode from "qrcode"; //二维码生成
import headinformation from "@/components/headinformation/index.vue";
export default {
  name: "collection",
  components: {
    headinformation,
  },
  data() {
    return {
      centerDialogVisibleWX: false, //微信弹窗
      centerDialogVisible: false, //弹窗
      reproductionSuccess: false,
      CollectionTitle: "",
      dialogItme: {}, //pc
      dialogItmeYD: {}, //移动
      total: 10, //总条目数
      pagesize: 12, //每页显示条目个数
      currentPage: 1, //当前页数
      aprenderObject: { list: [] },
      default: {
        share_collection_id: "",
        userId: "",
      },
    };
  },
  created() {
    this.getCollectionList(
      this.default.share_collection_id,
      this.default.userId
    );
  },
  watch: {
    $route: {
      handler: function (to, from) {
        if (
          to.query.projectId != "" &&
          to.query.projectId != undefined &&
          to.query.projectId != null
        ) {
          this.getCollectionList(to.query.projectId, to.query.userId);
        }
      },
      // 深度观察监听
      deep: true,
      immediate: true,
    },
  },
  methods: {
    //获取列表
    async getCollectionList(share_collection_id, userId) {
      const { data: data } = await this.$http.post("speaker/collection/list", {
        share_collection_id,
        userId,
        page: this.currentPage,
        limit: this.pagesize,
      });
      this.aprenderObject = data.data;
      this.$Spin.hide();
    },
    //查看
    collectionView(item) {
      this.$router.push({
        path: "/foreignSpeakers",
        query: {
          id: item.id,
          name: item.name,
        },
      });
    },
    //分享
    async shareClick(item) {
      this.centerDialogVisible = true;
      const { data: data } = await this.$http.post("speaker/share/collection", {
        collection_id: item.id,
      });
      this.CollectionTitle = data.data.collection_name;
      this.dialogItme = data.data.pc;
      this.dialogItmeYD = data.data.wechat;

      console.log(data);
    },
    //分享链接
    dialogShareLJ() {
      this.getReproduction(this.dialogItme.short_url);
    },
    //复制链接
    getReproduction(data) {
      let input = document.createElement("input"); // js创建一个input(textarea)输入框
      input.value = data;
      document.body.appendChild(input); //将输入框暂时创建到实例里面
      input.select(); // 选中输入框中的内容
      document.execCommand("Copy"); //执行复制操作
      document.body.removeChild(input); //最后删除实例中临时创建的input输入框，完成复制操作
      this.reproductionSuccess = true;
      setTimeout(() => {
        this.centerDialogVisible = false;
        this.reproductionSuccess = false;
      }, 500);
    },
    //分享微信
    dialogShareWX() {
      this.centerDialogVisibleWX = true;
      this.WXQrCode(this.dialogItmeYD.short_url);
    },
    //微信合成二维码
    WXQrCode(url) {
      if (!url) return;
      this.showCodeDialog = true;
      this.$nextTick(() => {
        QrCode.toCanvas(this.$refs.myCanvas, url);
      });
    },
    //删除
    collectionDelete(item) {
      this.$confirm("您是否要删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data: data } = await this.$http.post(
            "speaker/collection/delete",
            {
              id: item.id,
            }
          );
          this.$message({
            message: data.message,
            type: "success",
          });
          this.getCollectionList();
        })
        .catch(() => {});
    },
    //返回
    backProject() {
      this.$router.back();
    },
    //点击每一页
    currentchange(e) {
      this.currentPage = e;
      setTimeout(() => {
        this.getCollectionList();
      }, 500);
      this.$Spin.show();
    },
    //上一页
    prevClick(e) {
      this.currentPage = e;
      setTimeout(() => {
        this.getCollectionList();
      }, 500);
      this.$Spin.show();
    },
    //下一页
    nextClick(e) {
      this.currentPage = e;
      setTimeout(() => {
        this.getCollectionList();
      }, 500);
      this.$Spin.show();
    },
  },
};
</script>
<style lang="less" scoped>
.collection {
  min-height: 100vh;
  background-color: #f2f3f5 !important;

  /deep/ .el-button {
    font-size: 12px !important;
    padding: 9px 20px !important;
  }

  .collection-box {
    margin: 0 50px;
    background-color: #fff;
    .collection-box-head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 26px 42px 19px;
      border-bottom: 1px solid #e5e6eb;
    }
    //列表
    .ollection-box-ul {
      position: relative;
      padding: 35px 42px;
      display: flex;
      flex-wrap: wrap;
      min-height: 570px;
      .ollection-box-ul-li {
        position: relative;
        width: 24%;
        height: 224px;
        // background-color: pink;
        // margin-right: 27.5px;
        // margin-right: 1.9%;
        margin-right: 1.3%;
        margin-bottom: 27.5px;
        cursor: pointer;
        &:nth-child(4n) {
          margin-right: 0;
        }

        .ollection-box-ul-li-num {
          position: absolute;
          top: 72%;
          left: 10%;
        }
        .ollection-box-ul-li-name {
          position: absolute;
          top: 45%;
          left: 10%;
          color: #063b6b;
          font-size: 26px;
          font-weight: 600;
          z-index: 999;
        }

        .ollection-box-ul-li-icon {
          position: absolute;
          top: 10%;
          right: 5%;
          color: #063b6b;
          font-size: 14px;
          z-index: 1;
          font-weight: 600;
        }

        .ollection-box-ul-li-icon-shan {
          position: absolute;
          top: 25%;
          right: 5%;
          color: #063b6b;
          font-size: 14px;
          z-index: 1;
          font-weight: 600;
        }
      }
    }
  }

  //分页
  .page {
    margin: 0 50px;
    margin-top: 16px;
    background-color: #fff;
    text-align: center;
    padding: 5px 0;
  }
}

.dialog-box {
  position: relative;
  .dialog-heda {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: 1px solid #f6f6f6;
    .dialog-heda-img {
      width: 146px;
      height: 80px;
      margin-right: 40px;
    }

    .dialog-heda-box {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .dialog-heda-text {
        // min-height: 110px;
        min-height: 80px;
        font-weight: 600;
        font-size: 14px;
        color: #2d2d2d;
      }
      .dialog-heda-text-laiyuan {
        margin-top: 15px;
        font-size: 13px;
        color: #a8a8a8;
      }
    }
  }
  .dialog-share {
    margin-top: 22px;
    .dialog-share-fxz {
      font-size: 15px;
      color: #2d2d2d;
      margin-bottom: 22px;
    }
    .dialog-share-box {
      position: relative;
      z-index: 999;
      display: flex;
      align-items: center;
      .reproduction-success {
        border: 1px solid #8488ff;
        border-radius: 7px;
        padding: 7px 12px;
        margin-left: 15px;
        color: #8e8fab;
        font-size: 13px;
      }
      .dialog-share-box-wx {
        margin-right: 35px;
        cursor: pointer;
      }
      .dialog-share-box-lj {
        cursor: pointer;
      }
      .dialog-share-box-img {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 52px;
        height: 52px;
        background-color: #eeeeee;
        border-radius: 4px;
      }
      .dialog-share-box-text {
        font-size: 13px;
        color: #898989;
        margin-top: 5px;
      }

      .dialog-share-box-img-fx {
        width: 19px;
        height: 19px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .dialog-share-box-img-wx {
        width: 24px;
        height: 20px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
.dialog-bgc {
  // width: 125px;
  // height: 60px;
  width: 108px;
  height: 35px;
  z-index: 1;
  position: absolute;
  bottom: 0px;
  right: 0;
  img {
    width: 100%;
    height: 100%;
  }
}

.dialog-box-wx {
  position: relative;
  .dialog-box-wx-img-box {
    display: flex;
    justify-content: center;
    align-items: center;
    .dialog-box-wx-img {
      margin: 50px 0 35px 0;
      width: 146px;
      height: 146px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .dialog-box-wx-text {
    position: relative;
    font-size: 14px;
    color: #2d2d2d;
    z-index: 22;
    text-align: center;
    padding-bottom: 47px;
  }

  .dialog-bgc-wx {
    z-index: 1;
    position: absolute;
    bottom: -30px;
    left: -5px;
    width: 517px;
    height: 129px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
