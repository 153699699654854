<template>
  <div>
    <el-button
      :disabled="disable"
      :class="{ codeGeting: isGeting }"
      @click="getVerifyCode"
      style="background: #0e9ba9; color: #fff"
      v-prevent="2000"
      >{{ getCode }}
    </el-button>
  </div>
</template>

<script>
export default {
  name: "getCode",
  props: ["ruleForm", "type"],
  data() {
    return {
      countDownNum: 60,
      getCode: "获取验证码",
      isGeting: false,
      disable: false,
    };
  },
  methods: {
    //发送验证码
    getVerifyCode() {
      this.disable = true;
      this.getSms();
    },
    async getSms() {
      try {
        const { data: data } = await this.$http.post("auth/sms", {
          phone: this.ruleForm.phone,
          type: this.type,
        });
        this.disable = true;
        this.getCoun();
        this.$message({
          message: data.message,
          type: "success",
        });
      } catch (error) {
        this.disable = false;
        return;
      }
    },
    //定时器
    getCoun() {
      let time = setInterval(() => {
        this.countDownNum--;
        this.getCode = `${this.countDownNum}s 后重新发送`;
        if (this.countDownNum < 0) {
          this.getCode = "获取验证码";
          this.countDownNum = 60;
          this.disable = false;
          clearInterval(time);
        }
      }, 1000);
    },
  },
};
</script>
<style lang="less" scoped></style>
