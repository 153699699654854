import Vue from 'vue'
import VueRouter from 'vue-router'
import login from '@/views/entrance/login/index.vue'
import forgetPwd from '@/views/entrance/forgetPwd/index.vue'
import registered from '@/views/entrance/registered/index.vue'
import home from '@/views/home/index.vue'
import homeDetail from '@/views/homeDetail/index.vue' 
import collection from '@/views/collection/index.vue'  
import compare from '@/views/compare/index.vue'  
import foreignSpeakers from '@/views/collectionDetails/index.vue'  
import followDetails from '@/views/followDetails/index.vue'
//! 解决路由重复点击  
Vue.use(VueRouter)  
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)
const routes = [{  
        path: '/',
        redirect: '/login'
    }, 
  { 
        path: '/login',
        name: 'login',
        component: login
  },
  {
        path: '/forgetPwd',
        name: 'forgetPwd',
        component: forgetPwd 
  },
  {
        path: '/registered',
        name: 'registered',
        component: registered  
  },
  //首页
  {
        path: '/home',
        name: 'home',
        component: home   
  },  
  {
        path: '/homeDetail',
        name: 'homeDetail',
        component: homeDetail    
  },  
  {
        path: '/collection',
        name: 'collection',
        component: collection    
  },   
  {
        path: '/compare',
        name: 'compare',
        component: compare    
  },   
  {
        path: '/foreignSpeakers',
        name: 'foreignSpeakers',
        component: foreignSpeakers    
  },   
  {
        path: '/followDetails',
        name: 'followDetails',
        component: followDetails    
  },   
] 
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})
 
//* 判断用户是否登录  && to.path !== '/fillInQuestionnaires'    
router.beforeEach((to, from, next) => { 
if (to.path !== '/login' && to.path !== '/forgetPwd' && to.path !== '/registered' && to.path !== '/foreignSpeakers' && to.path !== '/homeDetail') { 
    if (!window.localStorage.getItem('token')) {  
        router.push('/login');  
    } else {
        next() 
    } 
} else {
    next()
} 
}) 

export default router