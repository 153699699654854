<template>
  <div class="home">
    <headinformation />
    <div class="home-box">
      <!-- 筛选 创建 -->
      <div class="create-btn">
        <!--//*用户 用来展示 开始 -->
        <div
          v-show="hashahahahah"
          class="createadd-popover hahahahahah"
          id="test3"
        >
          <!-- 内容 -->
          <ul class="createadd-popover-ul">
            <li
              class="createadd-popover-ul-li"
              v-for="(item, index) in collectionList"
              :key="item.id"
            >
              <div class="createadd-popover-ul-li-left">
                <div class="createadd-popover-ul-li-left-img">
                  <!-- <img src="../../assets/image/foreignnationals/wenjian.png" /> -->
                </div>
                <div class="createadd-popover-ul-li-left-text">
                  {{ item.name }}
                </div>
              </div>
              <div class="createadd-popover-ul-li-right" v-if="item.share == 0">
                <div
                  class="createadd-popover-ul-li-right-icon-d"
                  @click="collectionAdd(item, index)"
                >
                  <i class="el-icon-success"></i>
                </div>
                <div
                  v-if="item.count == 0"
                  class="createadd-popover-ul-li-right-icon-c"
                  @click="collectionDelete(item, index)"
                >
                  <i class="el-icon-error"></i>
                </div>
              </div>
            </li>
            <li
              v-if="!collectionList.length"
              style="text-align: center; padding: 10px 0"
            >
              暂无数据
            </li>
          </ul>
          <div
            class="createadd-popover-add"
            @click="categoryShow = !categoryShow"
          >
            <div>
              <i class="el-icon-plus"></i>
            </div>
            <div style="margin-left: 10px">添加分类</div>
          </div>
        </div>
        <!-- //*用户 用来展示 结束 -->
        <div class="create-btn-left-flex">
          <el-checkbox
            v-if="aprenderObject.list.length"
            class="checkbox-quan"
            :indeterminate="isIndeterminate"
            v-model="checkAll"
            @change="handleCheckAllChange"
            >全选</el-checkbox
          >
          <el-button
            @click="headDrawerReset"
            style="
              background: #0e9ba9;
              color: #fff;
              border-color: #0e9ba9;
              margin-right: 10px;
            "
            type="success"
            icon="el-icon-refresh"
            >刷新</el-button
          >
          <el-button
            @click="compareClick"
            style="
              background: #2a5e98;
              color: #fff;
              border-color: #2a5e98;
              margin-right: 20px;
            "
            type="primary"
            icon="el-icon-document-copy"
            :disabled="checkList.length == 0 ? true : false"
            >对比</el-button
          >
          <!-- 创建合集 弹窗 -->
          <el-popover
            id="test2"
            ref="popoverRef"
            placement="bottom-start"
            width="210"
            trigger="click"
            :offset="-50"
          >
            <!-- 按钮 -->
            <el-button
              slot="reference"
              type="primary"
              :disabled="checkList.length == 0 ? true : false"
            >
              <div class="create-btn-flex">
                <div class="create-btn-flex-img">
                  <img
                    src="../../assets/image/foreignnationals/chuangjian.png"
                  />
                </div>
                <div>创建合集</div>
              </div>
            </el-button>
            <!-- 按钮 结束 -->
            <!-- 盒子 -->
            <div class="createadd-popover">
              <!-- 内容 -->
              <ul class="createadd-popover-ul">
                <transition-group tag="ul" name="collection-list">
                  <li
                    class="createadd-popover-ul-li"
                    v-for="(item, index) in collectionList"
                    :key="item.id"
                  >
                    <div class="createadd-popover-ul-li-left">
                      <div class="createadd-popover-ul-li-left-img">
                        <img
                          src="../../assets/image/foreignnationals/wenjian.png"
                        />
                      </div>
                      <div class="createadd-popover-ul-li-left-text">
                        {{ item.name }}
                      </div>
                    </div>
                    <div
                      class="createadd-popover-ul-li-right"
                      v-if="item.share == 0"
                    >
                      <div
                        class="createadd-popover-ul-li-right-icon-d"
                        @click="collectionAdd(item, index)"
                      >
                        <i class="el-icon-success"></i>
                      </div>
                      <div
                        v-if="item.count == 0"
                        class="createadd-popover-ul-li-right-icon-c"
                        @click="collectionDelete(item, index)"
                      >
                        <i class="el-icon-error"></i>
                      </div>
                    </div>
                  </li>
                </transition-group>
                <transition name="collection-list-show">
                  <li
                    v-if="!collectionList.length"
                    style="text-align: center; padding: 10px 0"
                  >
                    暂无数据
                  </li>
                </transition>
              </ul>
              <!-- 内容 结束 -->
              <!-- 添加分类 -->
              <transition name="category-show">
                <div class="createadd-popover-bottom" v-if="categoryShow">
                  <div class="createadd-popover-bottom-input">
                    <el-input
                      v-model="addClassification"
                      placeholder="输入分类名称"
                    ></el-input>
                  </div>
                  <div
                    class="createadd-popover-bottom-icon"
                    @click="addCategory()"
                  >
                    <transition name="createadd-popover">
                      <i v-if="addClassification" class="el-icon-success"></i>
                    </transition>
                  </div>
                </div>
              </transition>
              <div
                class="createadd-popover-add"
                @click="categoryShow = !categoryShow"
              >
                <div>
                  <i class="el-icon-plus"></i>
                </div>
                <div style="margin-left: 10px">添加分类</div>
              </div>
            </div>
            <!-- 添加分类 结束 -->
            <!-- 盒子 结束 -->
          </el-popover>
          <!-- 创建合集 弹窗 -->

          <!-- 展示流程 -->
          <div class="description">
            <el-popover
              :offset="-20"
              placement="right-start"
              trigger="hover"
              style="border-radius: 4px"
            >
              <div slot="reference" class="description-btn">
                <img src="@/assets/image/foreignnationals/wenhao.png" />
              </div>
              <div class="description-box">
                <img src="@/assets/image/foreignnationals/shuoming.png" />
              </div>
            </el-popover>
          </div>
          <!-- 展示流程 -->
        </div>

        <div class="create-btn-right-flex">
          <div style="text-align: left">
            <el-button
              @click="drawerScreening"
              type="primary"
              style="
                background: #fda611;
                color: #fff;
                border-color: #fda611;
                margin-right: 20px;
              "
            >
              <div class="create-btn-flex">
                <div class="create-btn-flex-img">
                  <img src="../../assets/image/foreignnationals/shaixuan.png" />
                </div>
                <div>筛选</div>
              </div>
            </el-button>
          </div>
          <el-button
            @click="collectionClick"
            style="background: #2563eb; color: #fff; border-color: #2563eb"
            type="success"
            icon="el-icon-files"
            >我的合集</el-button
          >
        </div>
      </div>
      <!-- 筛选 创建 结束 -->
      <div class="available" v-if="!aprenderObject.list.length">
        当前页面无数据
      </div>

      <!-- 列表 -->
      <el-checkbox-group
        v-model="checkList"
        @change="handleCheckedCitiesChange"
      >
        <ul class="foreinger-list">
          <li
            class="foreinger-list-item"
            v-for="(item, index) in aprenderObject.list"
            :key="item.id"
          >
            <div class="foreinger-list-item-head-right">
              <div class="foreinger-list-item-head-right-lv" v-if="item.tags">
                <!-- {{ item.tags.join(",") }} -->
                {{ item.tags.split(",")[0] }}
              </div>
              <div class="foreinger-list-item-head-right-fen" v-if="item.tags">
                {{ item.tags.split(",")[1] }}
              </div>
              <div class="foreinger-list-item-head-right-lan" v-if="item.tags">
                {{ item.tags.split(",")[2] }}
              </div>
              <div class="foreinger-list-item-head-right-img">
                <img src="@/assets/image/foreignnationals/dou.png" />
              </div>
            </div>
            <el-checkbox
              id="test1"
              :label="item.id"
              class="checkbox-item checkbox-lalala"
            >
              <div style="line-height: 0px"><br /></div>
            </el-checkbox>
            <!-- //1 -->
            <div
              class="foreinger-list-item-aaaaa"
              @click.stop.prevent="todetail(item)"
            >
              <div class="foreinger-list-item-heda">
                <img :src="item.photo" />
              </div>
              <!-- //1 -->
              <!-- //2 -->
              <div class="foreinger-list-item-box">
                <!-- //3 -->
                <div class="foreinger-list-item-title">
                  <div class="foreinger-list-item-name">
                    {{ item.name }}
                  </div>
                  <div class="foreinger-list-item-country">
                    <span class="foreinger-list-item-country1"> 国家：</span>
                    <span class="foreinger-list-item-country2">
                      {{ item.country }}
                    </span>
                  </div>
                </div>
                <!-- //3 -->
                <!-- //4 -->
                <div class="foreinger-list-item-contents">
                  <!-- //5 -->
                  <div class="foreinger-list-item-contents-left">
                    <!-- 上 -->
                    <div class="foreinger-list-item-contents-left-appointments">
                      <div
                        class="foreinger-list-item-contents-left-appointments-box"
                      >
                        <div
                          class="foreinger-list-item-contents-left-general-w"
                        ></div>
                        <div
                          class="foreinger-list-item-contents-left-general-t"
                        >
                          就职机构及任职：
                        </div>
                      </div>
                      <div class="foreinger-list-item-contents-left-position">
                        {{ item.institution }}
                      </div>
                    </div>
                    <!-- 上 结束-->
                    <!-- 下 -->
                    <div class="foreinger-list-item-contents-left-appointments">
                      <div
                        class="foreinger-list-item-contents-left-appointments-box"
                      >
                        <div
                          class="foreinger-list-item-contents-left-general-w"
                        ></div>
                        <div
                          class="foreinger-list-item-contents-left-general-t"
                        >
                          擅长领域：
                        </div>
                      </div>
                      <div class="foreinger-list-item-contents-left-position-x">
                        {{ item.areas_of_expertise }}
                      </div>
                    </div>
                    <!-- 下 结束-->
                  </div>
                  <!-- //5 -->

                  <!-- //6 -->
                  <div class="foreinger-list-item-contents-right">
                    <div
                      class="foreinger-list-item-contents-right-box"
                      @click.stop.prevent="todetail(item)"
                    >
                      <div class="foreinger-list-item-contents-right-box-img">
                        <img
                          src="@/assets/image/foreignnationals/xiangqing.png"
                        />
                      </div>
                      <div class="foreinger-list-item-contents-right-box-text">
                        详情
                      </div>
                    </div>
                    <!-- 关注 取消关注 -->
                    <div @click.stop.prevent="expertFollowClick(item)">
                      <div
                        class="foreinger-list-item-contents-right-box"
                        v-if="item.is_follow == 0"
                      >
                        <div class="foreinger-list-item-contents-right-box-img">
                          <img
                            src="@/assets/image/foreignnationals/guanzhu.png"
                          />
                        </div>
                        <div
                          class="foreinger-list-item-contents-right-box-text"
                        >
                          关注
                        </div>
                      </div>

                      <div
                        class="foreinger-list-item-contents-right-box"
                        v-if="item.is_follow == 1"
                      >
                        <div class="foreinger-list-item-contents-right-box-img">
                          <img
                            src="@/assets/image/foreignnationals/quxiaoguanzhu.png"
                          />
                        </div>
                        <div
                          class="foreinger-list-item-contents-right-box-text"
                        >
                          取消关注
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- //6 -->
                </div>
                <!-- //4 -->
              </div>
              <!-- //2 -->
            </div>
          </li>
        </ul>
      </el-checkbox-group>
      <!-- 列表 结束 -->
      <!-- 创建合集 -->
      <!-- 创建合集 结束 -->
      <div class="page" v-show="aprenderObject.list">
        <el-pagination
          layout="prev, pager, next"
          @current-change="currentchange"
          @prev-click="prevClick"
          @next-click="nextClick"
          :current-page.sync="currentPage"
          :page-count="aprenderObject.pages"
          hide-on-single-page
        >
        </el-pagination>
      </div>
      <!-- 结束 -->
      <!--推荐弹窗 -->
      <el-dialog :visible.sync="dialogVisible" width="30%">
        <div class="dialog-recommend">
          <div class="dialog-recommend-name">推荐：</div>
          <div class="dialog-recommend-select">
            <el-select v-model="recommend_id" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="dialog-reason">
          <div class="dialog-reason-name">推荐理由：</div>
          <div class="dialog-reason-input">
            <el-input
              type="textarea"
              autosize
              placeholder="请输入内容"
              v-model="argument"
              :autosize="{ minRows: 4, maxRows: 4 }"
            >
            </el-input>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="referralSubmission"
            >确 定</el-button
          >
        </span>
      </el-dialog>
      <!--推荐弹窗 -->
      <!-- 筛选 -->
      <el-drawer title="筛选条件" :visible.sync="drawerShow" size="35%">
        <div class="dialog-pad">
          <!-- 输入框 -->
          <div class="drawer-contents">
            <!-- //1 -->
            <div class="drawer-contents-pai">
              <div class="drawer-contents-pai-l">
                <div class="drawer-contents-pai-t">讲者姓名</div>
                <div class="drawer-contents-pai-b">
                  <el-input v-model="screening.screeningNmae"></el-input>
                </div>
              </div>
              <div class="drawer-contents-pai-l">
                <div class="drawer-contents-pai-t">城市/国家</div>
                <div class="drawer-contents-pai-b">
                  <el-input v-model="screening.screeningCity"></el-input>
                </div>
              </div>
            </div>
            <!-- //1 -->
            <!-- //2 -->
            <div class="drawer-contents-pai">
              <div class="drawer-contents-pai-l">
                <div class="drawer-contents-pai-t">时区时差</div>
                <div class="drawer-contents-pai-b">
                  <el-input v-model="screening.screeningTimelag"></el-input>
                </div>
              </div>
              <div class="drawer-contents-pai-l">
                <div class="drawer-contents-pai-t">医疗领域</div>
                <div class="drawer-contents-pai-b">
                  <el-input v-model="screening.screeningMedical"></el-input>
                </div>
              </div>
            </div>
            <!-- //2 -->
            <!-- //3 -->
            <div class="drawer-contents-pai">
              <div class="drawer-contents-pai-l">
                <div class="drawer-contents-pai-t">擅长领域</div>
                <div class="drawer-contents-pai-b">
                  <el-input v-model="screening.screeningExpertise"></el-input>
                </div>
              </div>
              <div class="drawer-contents-pai-l">
                <div class="drawer-contents-pai-t">会议演讲及题目</div>
                <div class="drawer-contents-pai-b">
                  <el-input
                    v-model="screening.screeningPresentations"
                  ></el-input>
                </div>
              </div>
            </div>
            <!-- //3 -->
            <!-- //4 -->
            <div class="drawer-contents-pai">
              <div class="drawer-contents-pai-l" style="width: 100%">
                <div class="drawer-contents-pai-t">学协会及任职</div>
                <div class="drawer-contents-pai-b">
                  <el-input
                    type="textarea"
                    v-model="screening.screeningAppointments"
                  ></el-input>
                </div>
              </div>
            </div>
            <div class="drawer-contents-pai">
              <div class="drawer-contents-pai-l" style="width: 100%">
                <div class="drawer-contents-pai-t">机构/职位</div>
                <div class="drawer-contents-pai-b">
                  <el-input
                    type="textarea"
                    v-model="screening.screeningInstitutions"
                  ></el-input>
                </div>
              </div>
            </div>
            <!-- //4 -->
            <!-- //5 -->
            <div class="drawer-contents-pai">
              <div class="drawer-contents-pai-l" style="width: 100%">
                <div class="drawer-contents-pai-t">演讲研究赞助商</div>
                <div class="drawer-contents-pai-b">
                  <el-input
                    type="textarea"
                    v-model="screening.screeningSponsorship"
                  ></el-input>
                </div>
              </div>
            </div>
            <div class="drawer-contents-pai">
              <div class="drawer-contents-pai-l" style="width: 100%">
                <div class="drawer-contents-pai-t">期刊及任职及影响因子</div>
                <div class="drawer-contents-pai-b">
                  <el-input
                    type="textarea"
                    v-model="screening.screeningImpact"
                  ></el-input>
                </div>
              </div>
            </div>
            <!-- //5 -->
          </div>
          <!-- 输入框 结束 -->
          <!-- 查询 -->
          <div class="drawer-btn">
            <el-button
              size="medium"
              style="background: #0e9ba9; color: #fff; margin-right: 20px"
              @click="drawerSearch"
              >查询</el-button
            >
            <el-button
              size="medium"
              style="background: #2563eb; color: #fff"
              @click="drawerReset"
              >重置</el-button
            >
          </div>
          <!-- 查询 结束 -->
        </div>
      </el-drawer>
      <!-- 筛选 -->
    </div>
  </div>
</template>

<script>
import headinformation from "@/components/headinformation/index.vue";
export default {
  name: "home",
  components: {
    headinformation,
  },
  data() {
    return {
      options: [
        {
          value: "1",
          label: "首推",
        },
        {
          value: "2",
          label: "第二推荐",
        },
        {
          value: "3",
          label: "第三推荐",
        },
        {
          value: "4",
          label: "第四推荐",
        },
        {
          value: "5",
          label: "第五推荐",
        },
        {
          value: "6",
          label: "第六推荐",
        },
      ],
      recommend_id: "",
      argument: "",
      dialogVisible: false,
      recommendText: "",
      hashahahahah: false, //新手引导
      input: "",
      checkList: [], // 已经选中的数据
      checkedKeyLists: [], // key的list数据
      checkAll: false, // 全选
      isIndeterminate: false, // 全选的标记
      SelectAllApiList: [], //全选接口
      drawerShow: false,
      checkedCount: [],
      screening: {
        screeningNmae: "",
        screeningCity: "",
        screeningTimelag: "",
        screeningMedical: "",
        screeningExpertise: "",
        screeningPresentations: "",
        screeningAppointments: "",
        screeningInstitutions: "",
        screeningSponsorship: "",
        screeningImpact: "",
      },
      categoryShow: false,
      addClassification: "", //添加分类
      collectionList: [],
      CollectionPage: 1,
      loading: false,
      userinfo: {},
      //外籍讲者
      total: 10, //总条目数
      pagesize: 8, //每页显示条目个数
      currentPage: 1, //当前页数
      aprenderObject: { list: [] },
      //*新手指引
      stepss: [
        {
          element: "#test1",
          popover: {
            title: "说明：",
            description: "第一步：选择外籍讲者",
            position: "right",
          },
        },
        {
          element: "#test2",
          popover: {
            title: "说明：",
            description: "第二步：创建合集分类",
            position: "bottom",
          },
        },
        {
          element: "#test3",
          popover: {
            title: "说明：",
            description:
              "第三步：选择合集分类，创建完成的合集在【我的合集】查看",
            position: "bottom",
          },
          onDeselected: () => {
            this.hashahahahah = false;
          }, //跳过 完成
        },
      ],
    };
  },
  //keep-alive 激活触发
  created() {
    this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
    this.getCollectionList(); //合集列表
    this.getSelectAllApi(); //获取全选 接口
    this.getAprenderList();
  },
  methods: {
    //新手引导
    getguide() {
      this.hashahahahah = true;
      this.$driver.defineSteps(this.stepss);
      this.$driver.start();
    },
    //点击搜索
    searchExpert() {
      this.currentPage = 1;
      this.screening.screeningNmae = "";
      this.screening.screeningCity = "";
      this.screening.screeningTimelag = "";
      this.screening.screeningMedical = "";
      this.screening.screeningExpertise = "";
      this.screening.screeningPresentations = "";
      this.screening.screeningAppointments = "";
      this.screening.screeningInstitutions = "";
      this.screening.screeningSponsorship = "";
      this.screening.screeningImpact = "";
      this.checkedKeyLists = [];
      this.checkList = [];
      this.checkAll = false;
      setTimeout(() => {
        this.getAprenderList();
        this.getSelectAllApi(); //全选
      }, 500);
      this.$Spin.show();
    },
    //回车搜索
    change(value) {
      this.input = value;
      this.currentPage = 1;
      this.screening.screeningNmae = "";
      this.screening.screeningCity = "";
      this.screening.screeningTimelag = "";
      this.screening.screeningMedical = "";
      this.screening.screeningExpertise = "";
      this.screening.screeningPresentations = "";
      this.screening.screeningAppointments = "";
      this.screening.screeningInstitutions = "";
      this.screening.screeningSponsorship = "";
      this.screening.screeningImpact = "";
      setTimeout(() => {
        this.getAprenderList();
        this.getSelectAllApi(); //全选
      }, 500);
      this.checkedKeyLists = [];
      this.checkList = [];
      this.checkAll = false;
      this.$Spin.show();
    },
    //全选请求接口
    async getSelectAllApi() {
      //  speaker/list2
      const { data: data } = await this.$http.post("speaker/lists", {
        name: this.screening.screeningNmae, //讲者姓名
        country: this.screening.screeningCity, //国家
        time_zone: this.screening.screeningTimelag, //时区
        medical_category: this.screening.screeningMedical, //医疗领域
        areas_of_expertise: this.screening.screeningExpertise, //擅长领域
        presenting_meeting: this.screening.screeningPresentations, //会议演讲及题目
        association: this.screening.screeningAppointments, //学协会及任职
        institution: this.screening.screeningInstitutions, //就只机构/机构职位
        research_sponsor: this.screening.screeningSponsorship, //演讲研究赞助商
        journal_position: this.screening.screeningImpact, //筛选-期刊及任职及影响因子
        global_search: this.input, //全局搜索
      });
      this.SelectAllApiList = data.data;
      // 数据 257 条
      this.init();
      //点击全选的时候 请求数据 是否是全选
      if (this.checkAll) {
        this.checkedKeyLists = [...new Set(this.checkedKeyLists)];
        this.checkList = this.checkedKeyLists;
      }
    },
    //筛选key的数据
    init() {
      this.checkedKeyLists = [];
      this.checkList = [];
      this.SelectAllApiList.forEach((el) => {
        this.checkedKeyLists.push(el.id);
      });
    },
    //全选
    handleCheckAllChange(value) {
      this.checkedKeyLists = [...new Set(this.checkedKeyLists)];
      this.checkList = value ? this.checkedKeyLists : [];
      this.isIndeterminate = false;
      // console.log(this.checkedKeyLists);
    },
    //单选
    handleCheckedCitiesChange(value) {
      let checkedCount = [...new Set(value)].length;
      let aaa = [...new Set(this.checkedKeyLists)].length;
      this.checkAll = checkedCount === aaa;
      this.isIndeterminate =
        checkedCount > 0 &&
        checkedCount < [...new Set(this.checkedKeyLists)].length;
    },
    //筛选
    drawerScreening() {
      this.drawerShow = true;
    },
    //重置
    headDrawerReset() {
      this.$Spin.show();
      this.currentPage = 1;
      this.input = "";
      this.screening.screeningNmae = "";
      this.screening.screeningCity = "";
      this.screening.screeningTimelag = "";
      this.screening.screeningMedical = "";
      this.screening.screeningExpertise = "";
      this.screening.screeningPresentations = "";
      this.screening.screeningAppointments = "";
      this.screening.screeningInstitutions = "";
      this.screening.screeningSponsorship = "";
      this.screening.screeningImpact = "";
      this.checkedKeyLists = [];
      this.checkList = [];
      this.checkAll = false;
      setTimeout(() => {
        this.getAprenderList();
        this.getSelectAllApi(); //全选
      }, 500);
    },
    //对比
    compareClick() {
      if (this.checkList.length == 0) {
        this.$message("请选择对比数据");
        return;
      }
      if (this.checkList.length > 5) {
        this.$message("只能选择5个进行比对");
        return;
      }
      //少于5个
      let list = JSON.stringify(this.checkList);
      this.$router.push({
        path: "/compare",
        query: {
          list,
        },
      });
    },
    //搜索查找
    drawerSearch() {
      this.input = "";
      this.$Spin.show();
      this.checkedKeyLists = [];
      this.checkList = [];
      this.checkAll = false;
      setTimeout(() => {
        this.getAprenderList();
        this.getSelectAllApi(); //全选
      }, 500);
      this.drawerShow = false;
    },
    //搜索重置
    drawerReset() {
      this.screening.screeningNmae = "";
      this.screening.screeningCity = "";
      this.screening.screeningTimelag = "";
      this.screening.screeningMedical = "";
      this.screening.screeningExpertise = "";
      this.screening.screeningPresentations = "";
      this.screening.screeningAppointments = "";
      this.screening.screeningInstitutions = "";
      this.screening.screeningSponsorship = "";
      this.screening.screeningImpact = "";
      this.checkedKeyLists = [];
      this.checkList = [];
      this.checkAll = false;
      this.getAprenderList();
      this.getSelectAllApi(); //全选
      // this.drawerShow = false
    },
    //合集列表
    async getCollectionList() {
      const { data: data } = await this.$http.get("speaker/collection/cate");
      this.collectionList = data.data;
    },
    //存放合集
    async collectionAdd(item) {
      let list = [...new Set(this.checkList)]; //数组去重
      const { data: data } = await this.$http.post("speaker/put/speakers", {
        collection_id: item.id,
        speaker_id: list,
      });
      this.getCollectionList();
      this.$router.push("/collection");
      this.$message({
        message: data.message,
        type: "success",
      });
    },
    //删除合集
    async collectionDelete(item) {
      const { data: data } = await this.$http.post(
        "speaker/collection/delete",
        {
          id: item.id,
        }
      );
      this.getCollectionList();
      setImmediate(() => {
        this.$message({
          message: data.message,
          type: "success",
        });
      }, 500);
    },
    //添加集合
    async addCategory() {
      const { data: data } = await this.$http.post("speaker/guest/collection", {
        id: "", //修改时需要
        name: this.addClassification,
        photo: "", //封面图
      });
      this.getCollectionList();
      this.addClassification = "";
      this.$message({
        message: data.message,
        type: "success",
      });
    },
    //我的合集
    collectionClick() {
      this.$router.push({
        path: "/collection",
      });
    },
    //获取外籍讲者列表
    async getAprenderList() {
      const { data: data } = await this.$http.post("speaker/list", {
        page: this.currentPage, //第几页
        limit: this.pagesize, //每页条数
        name: this.screening.screeningNmae, //讲者姓名
        country: this.screening.screeningCity, //国家
        time_zone: this.screening.screeningTimelag, //时区
        medical_category: this.screening.screeningMedical, //医疗领域
        areas_of_expertise: this.screening.screeningExpertise, //擅长领域
        presenting_meeting: this.screening.screeningPresentations, //会议演讲及题目
        association: this.screening.screeningAppointments, //学协会及任职
        institution: this.screening.screeningInstitutions, //就只机构/机构职位
        research_sponsor: this.screening.screeningSponsorship, //演讲研究赞助商
        journal_position: this.screening.screeningImpact, //筛选-期刊及任职及影响因子
        global_search: this.input, //全局搜索
      });
      this.aprenderObject = data.data;
      this.$Spin.hide();
      //新手引导
      setTimeout(() => {
        this.judgementNewUsers();
      }, 100);
    },
    //判断是否创建合集 展示 新手指引
    async judgementNewUsers() {
      const { data: data } = await this.$http.get("speaker/collection/isFirst");
      if (data.data.is_first != 0) {
        this.getguide();
      }
    },
    // 外籍讲者详情
    todetail(item) {
      this.$router.push({
        path: "/homeDetail",
        query: {
          id: item.id,
        },
      });
    },
    //关注 取消关注 点击
    expertFollowClick(item) {
      let status = 0;
      if (item.is_follow == 0) {
        status = 1;
      }
      if (item.is_follow == 1) {
        status = 0;
      }
      if (status == 0) {
        this.$confirm("您是否要取消关注？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(async () => {
          this.getuserFollow(item.id, status);
        });
      } else {
        this.getuserFollow(item.id, status);
      }
    },
    //关注 取消关注 接口
    async getuserFollow(info_id, status) {
      const { data: data } = await this.$http.post("speaker/follow", {
        info_id,
        type_id: 8,
        status,
      });
      this.getAprenderList();
      this.$message({
        message: data.message,
        type: "success",
      });
    },
    //推荐合集
    recommendList(item) {
      this.recommendText = item.id;
      this.recommend_id = "";
      this.argument = "";
      this.dialogVisible = true;
    },
    //推荐提交
    async referralSubmission() {
      this.dialogVisible = false;
      const { data: data } = await this.$http.post("recommend/speaker", {
        collection_id: "",
        speaker_id: this.recommendText,
        recommend_id: this.recommend_id,
        argument: this.argument,
      });
      console.log(data);
    },
    //点击每一页
    currentchange(e) {
      this.currentPage = e;
      // document.querySelector(".el-main").scrollTop = 0;
      setTimeout(() => {
        this.getAprenderList();
      }, 500);
      this.$Spin.show();
      this.checkedKeyLists = [...new Set(this.checkedKeyLists)];
    },
    //上一页
    prevClick(e) {
      this.currentPage = e;
      setTimeout(() => {
        // this.getAprenderList();
      }, 500);
      this.$Spin.show();
      this.checkedKeyLists = [...new Set(this.checkedKeyLists)];
    },
    //下一页
    nextClick(e) {
      this.currentPage = e;
      setTimeout(() => {
        // this.getAprenderList();
      }, 500);
      this.$Spin.show();
      this.checkedKeyLists = [...new Set(this.checkedKeyLists)];
    },
  },
};
</script>
<style lang="less" scoped>
.home {
  background-color: #f2f3f5 !important;
}

.home-box {
  margin: 0 50px;
  padding-bottom: 12px;
  .create-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    background-color: #fff;
    padding: 20px 40px;

    .create-btn-left-flex {
      display: flex;
      align-items: center;
    }
    .create-btn-right-flex {
      display: flex;
      align-items: center;
    }
    .checkbox-quan {
      display: flex;
      margin-right: 26px;
      /deep/ .el-checkbox__inner::before {
        top: 7px;
      }
    }
    .create-btn-flex {
      display: flex;
      justify-content: center;
      align-items: center;
      .create-btn-flex-img {
        width: 12px;
        height: 12px;
        margin-right: 5px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .foreinger-list {
    position: relative;
    min-height: 545px;
    overflow: hidden;

    .foreinger-list-item {
      position: relative;
      margin-bottom: 10px;
      // padding: 17px 0 22px 19px;
      padding: 17px 30px 22px 40px;
      border: 1px solid #f0f0f0;
      background-color: #fff;
      border-radius: 8px;
      // display: flex;

      /deep/ .el-checkbox__label {
        width: 100%;
        margin-top: 20px;
        padding-left: 0px;
        display: flex;
      }

      .foreinger-list-item-aaaaa {
        display: flex;
        cursor: pointer;
      }
      .foreinger-list-item-heda {
        width: 61px;
        height: 61px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 19px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .foreinger-list-item-box {
        flex: 1;
        .foreinger-list-item-title {
          display: flex;
          align-items: center;
          margin-bottom: 33px;
          .foreinger-list-item-name {
            color: #212529;
            font-size: 18px;
            font-weight: 600;
            max-width: 985px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .foreinger-list-item-country {
            margin-left: 160px;
            .foreinger-list-item-country1 {
              color: #212529;
              font-size: 18px;
              font-weight: 600;
            }
            .foreinger-list-item-country2 {
              font-size: 13px;
              color: #212529;
            }
          }
        }

        .foreinger-list-item-contents {
          // display: flex;
          .foreinger-list-item-contents-left {
            // width: 760px;
            width: 100%;
            .foreinger-list-item-contents-left-appointments {
              .foreinger-list-item-contents-left-appointments-box {
                display: flex;
                align-items: center;
                margin-bottom: 12px;
                .foreinger-list-item-contents-left-general-w {
                  width: 10px;
                  height: 4px;
                  background-color: #4687c8;
                  margin-right: 5px;
                }
                .foreinger-list-item-contents-left-general-t {
                  font-size: 13px;
                  color: #212529;
                }
              }
              .foreinger-list-item-contents-left-position {
                font-size: 13px;
                color: #c7c7c7;
                margin-bottom: 27px;
              }
              .foreinger-list-item-contents-left-position-x {
                font-size: 13px;
                color: #c7c7c7;
              }
            }
          }
          .foreinger-list-item-contents-right {
            flex: 1;
            display: flex;
            align-self: flex-end;
            justify-content: flex-end;
            .foreinger-list-item-contents-right-box {
              display: flex;
              align-items: center;
              justify-content: center;
              margin-right: 30px;
              cursor: pointer;
              // .foreinger-list-item-contents-right-box-img {
              // }
              .foreinger-list-item-contents-right-box-text {
                font-size: 14px;
                color: #212529;
                margin: 0 0 5px 5px;
                margin-top: 5px;
              }
            }
          }
        }
        //
      }
    }
  }

  .foreinger-list-item-head-right {
    position: relative;
    font-size: 13px;
    color: #212529;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .foreinger-list-item-head-right-lv {
      padding: 5px 12px;
      background-color: #bee3df;
      border-radius: 3px;
      margin-right: 20px;
    }
    .foreinger-list-item-head-right-fen {
      padding: 5px 12px;
      background-color: #f3d0d0;
      border-radius: 3px;
      margin-right: 20px;
    }
    .foreinger-list-item-head-right-lan {
      padding: 5px 12px;
      background-color: #d0e5f9;
      border-radius: 3px;
      margin-right: 36px;
    }
    .foreinger-list-item-head-right-img {
      width: 33px;
      height: 29px;
    }
  }
  //第一次进入页面
  .hahahahahah {
    z-index: 999;
    position: absolute;
    top: 135px;
    left: 166px;
    background-color: #fff;
    border: 1px solid #ebeef5;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  }
  //1 新增 结束

  //分页
  .available {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translateX(-50%);
    color: #8588ae;
  }
  // 2 筛选
  .drawer {
    position: relative;
    padding: 0 10px;
    .drawer-head {
      margin-top: 16px;
      display: flex;
      align-items: center;
      .drawer-head-img {
        width: 31px;
        height: 31px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .drawer-head-text {
        font-size: 18px;
        color: #212529;
        font-weight: 600;
      }
    }
    //内容
    .drawer-contents {
      margin-top: 50px;
      .drawer-contents-pai {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 45px;
        .drawer-contents-pai-l {
          width: 45%;
          .drawer-contents-pai-t {
            margin-bottom: 12px;
            font-size: 14px;
            color: #212529;
          }
        }
      }
    }
  }

  // 删除动画
  // 显示
  .collection-list-enter-active {
    animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }
  @keyframes slide-in-right {
    0% {
      transform: translateX(1000px);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }

  //隐藏
  .collection-list-leave-to {
    animation: slide-out-right 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  }
  @keyframes slide-out-right {
    0% {
      transform: translateX(0);
      opacity: 1;
    }
    100% {
      transform: translateX(1000px);
      opacity: 0;
    }
  }
  //添加分类 input显示隐藏
  .category-show-enter-active {
    animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }
  .category-show-leave-to {
    animation: slide-out-right 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  }
  //暂无数据
  .collection-list-show-enter-active {
    animation: slide-in-right 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }
  .collection-list-show-leave-to {
    animation: slide-out-right 0.7s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  }
  //111111111111111111111111111111111111111111111
  .createadd-popover-ul::-webkit-scrollbar {
    width: 0px !important;
  }

  .dialog-recommend {
    display: flex;
    align-items: center;
    .dialog-recommend-name {
      margin-right: 10px;
      font-size: 15px;
      color: #2d2d2d;
      font-weight: 600;
      margin-left: 30px;
    }
    .dialog-recommend-select {
    }
  }
  .dialog-reason {
    margin-top: 30px;
    display: flex;
    align-items: center;
    .dialog-reason-name {
      margin-right: 10px;
      font-size: 15px;
      color: #2d2d2d;
      font-weight: 600;
    }
    .dialog-reason-input {
      width: 80%;
    }
  }

  //分页
  .page {
    background-color: #fff;
    text-align: center;
    // padding: 5px 0;
  }
}

//说明弹窗
.description-box {
  width: 424px;
  height: 180px;
  img {
    width: 100%;
    height: 100%;
  }
}

/deep/ .el-button {
  font-size: 12px !important;
  padding: 9px 20px !important;
}

.dialog-pad {
  padding: 0 30px;
}
//内容
.drawer-contents {
  // margin-top: 50px;
  .drawer-contents-pai {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // margin-bottom: 38px;
    margin-bottom: 30px;
    .drawer-contents-pai-l {
      width: 45%;
      .drawer-contents-pai-t {
        margin-bottom: 12px;
        font-size: 14px;
        color: #212529;
      }
    }
  }
}
//搜索
.drawer-btn {
  display: flex;
  align-items: center;
  // justify-content: center;
  margin-top: 5px;
  margin-bottom: 20px;
}

/deep/ .el-input__inner {
  height: 34px;
  line-height: 34px;
}

//创建合集

.createadd-popover {
  overflow: hidden;
  ::-webkit-scrollbar {
    display: none;
  }
  .createadd-popover-ul {
    margin: 5px 0;
    max-height: 275px;
    overflow: hidden;
    overflow-y: scroll;
    padding: 0 6px;
    .createadd-popover-ul-li {
      padding: 5px 10px;
      transition: 0.3s all;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      transition: 0.3 all;
      color: #212529;
      &:last-child {
        margin-bottom: 0px;
      }
      &:hover {
        border-radius: 6px;
        background-color: #eeeeee;
        cursor: pointer;
      }
      .createadd-popover-ul-li-left {
        display: flex;
        align-items: center;
        .createadd-popover-ul-li-left-img {
          width: 14px;
          height: 12px;
          margin-right: 15px;
          margin-bottom: 5px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .createadd-popover-ul-li-left-text {
          width: 90px;
          padding: 0 5px;
          font-size: 13px;
          color: #212529;
        }
      }
      .createadd-popover-ul-li-right {
        display: flex;
        align-items: center;
        font-size: 18px;
        .createadd-popover-ul-li-right-icon-d {
          // margin-right: 12px;
          margin-right: 2px;
          color: #68bf7b;
          cursor: pointer;
        }
        .createadd-popover-ul-li-right-icon-c {
          color: #919191;
          cursor: pointer;
        }
      }
    }
  }
  .createadd-popover-bottom {
    box-shadow: 0px -1px 6px rgba(38, 129, 233, 0.16);
    padding: 8px 18px;
    display: flex;
    align-items: center;
    overflow: hidden;
    .createadd-popover-bottom-input {
      width: 132px;
      margin-right: 16px;
    }
    .createadd-popover-bottom-icon {
      cursor: pointer;
      font-size: 22px;
      color: #4e7be5;
    }
  }
  .createadd-popover-add {
    cursor: pointer;
    height: 40px;
    background-color: #75b6ff;
    color: #fff;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0px 0px 8px 8px;
  }
  .loading-cen {
    font-size: 13px;
    text-align: center;
    margin-top: 15px;
  }

  // 添加input动画
  //显示
  .createadd-popover-enter-active {
    animation: swirl-in-fwd 0.5s ease-out both;
  }
  @keyframes swirl-in-fwd {
    0% {
      transform: rotate(-540deg) scale(0);
      opacity: 0;
    }
    100% {
      transform: rotate(0) scale(1);
      opacity: 1;
    }
  }
  //隐藏
  .createadd-popover-leave-to {
    animation: swirl-out-bck 0.5s ease-in both;
  }
  @keyframes swirl-out-bck {
    0% {
      transform: rotate(0) scale(1);
      opacity: 1;
    }
    100% {
      transform: rotate(-540deg) scale(0);
      opacity: 0;
    }
  }
}
//说明
.description {
  margin-left: 10px;
  overflow: hidden;
  .description-btn {
    width: 16px;
    height: 16px;
    margin-bottom: 15px;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
<style>
.el-popover {
  padding: 0 !important;
}

.checkbox-item {
  position: relative;
  width: 18px !important;
  height: 18px !important;
}
</style>

<style>
[class*="driver-close-btn"] {
  visibility: hidden;
}
[class*="driver-prev-btn"] {
  visibility: hidden;
}
div#driver-popover-item .driver-popover-footer button {
  background-color: #409eff !important;
  border-color: #409eff !important;
  color: #fff !important;
  text-shadow: none !important; /* 取消原来的text阴影，不然会有文 字重叠的感觉 */
  border: none !important; /* 取消原来它自己按钮设置的border */
  line-height: 1 !important;
  border-radius: 4px !important;
  padding: 12px 20px !important;
}
</style>
