<template>
  <!-- 外侧 -->
  <div class="login">
    <div class="form-login">
      <!-- 登录 -->
      <div class="form-login-center">
        <div class="form-login-mask">
          <img src="@/assets/image/login/login.png" />
        </div>
        <div class="form-login-content-box">
          <div class="form-login-content-box-head">登录</div>
          <div class="form-login-content">
            <!-- 账号登录、微信登录 -->
            <div class="class-login">
              <div
                class="button-list"
                v-for="(item, index) in buttonList"
                :class="buttonIndex === index ? 'button-active' : ''"
                @click="tableswitch(index)"
              >
                {{ item }}
              </div>
            </div>
            <!-- 账号登录 -->
            <div v-show="buttonIndex == 0" class="account">
              <div class="phone-password">
                <el-form
                  :model="ruleForm"
                  status-icon
                  :rules="rules"
                  ref="ruleForm"
                  label-width="100px"
                  class="demo-ruleForm"
                  label-position="top"
                >
                  <el-form-item label="手机号" prop="username">
                    <el-input
                      type="text"
                      v-model="ruleForm.username"
                      autocomplete="off"
                      clearable
                      placeholder="请输入手机号"
                    ></el-input>
                  </el-form-item>
                  <el-form-item
                    label="密码"
                    prop="password"
                    class="add-password"
                  >
                    <el-input
                      type="password"
                      v-model="ruleForm.password"
                      autocomplete="off"
                      clearable
                      show-password
                      placeholder="请输入密码"
                    ></el-input>
                  </el-form-item>
                  <div class="remember-box">
                    <div @click="getforgetPwd">忘记密码</div>
                  </div>
                  <div class="submit">
                    <el-button
                      @click="submitForm('ruleForm')"
                      style="
                        background: #0e9ba9;
                        color: #fff;
                        width: 315px;
                        border-color: #0e9ba9;
                      "
                      v-prevent="3000"
                      >登录</el-button
                    >
                  </div>
                </el-form>
                <!-- 注册 -->
                <div class="registered" @click="getRegistered">
                  没有账号去注册
                </div>
                <!-- 底部背景图 -->
                <div class="buttom-img">
                  <!-- <img src="../../assets/image/login/buttom-bgc.png" /> -->
                </div>
              </div>
            </div>
            <!-- 微信登录 -->
            <div v-show="buttonIndex == 1" class="wx-login">
              <div id="qrcode"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      :visible.sync="dialogTableVisible"
      width="25%"
      title="绑定手机号"
    >
      <div class="dialog-weixin">
        <img src="@/assets/image/login/wexin.png" />
      </div>
      <div class="dialog-btn-input">
        <el-input
          style="margin: 26px 0 26px 0"
          v-model="inputpo"
          placeholder="请输入手机号"
        ></el-input>
        <div class="dialog-btn-input-code">
          <el-input
            style="width: 200px"
            v-model="inputco"
            placeholder="请输入验证码"
          ></el-input>
          <div>
            <get-code :ruleForm="ruleForm" :type="'INFO'" />
          </div>
        </div>
        <el-input
          style="margin: 26px 0 0 0"
          v-model="inputgo"
          placeholder="请输入公司名称"
        ></el-input>
        <div class="pw-text">密码默认123456</div>
        <el-button
          style="
            width: 100%;
            background: #0e9ba9;
            color: #fff;
            border-color: #0e9ba9;
          "
          type="primary"
          @click="wxbind"
          >确认</el-button
        >
      </div>
      <div class="dialog-logo"><img src="@/assets/image/login/logo.png" /></div>
    </el-dialog>
  </div>
</template>

<script>
import getCode from "@/components/getCode/index.vue";
export default {
  name: "login",
  components: {
    getCode,
  },
  data() {
    //手机
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入手机号"));
      } else {
        callback();
      }
    };
    //密码
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        callback();
      }
    };
    return {
      open_id: "",
      inputpo: "",
      inputgo: "",
      inputco: "",
      buttonIndex: 0,
      buttonList: ["账号登录", "微信登录"], //["账号登录", "微信登录"],
      ruleForm: {
        username: "",
        password: "",
      },
      rules: {
        username: [{ validator: validatePass, trigger: "blur" }],
        password: [{ validator: validatePass2, trigger: "blur" }],
      },
      checked: true,
      //项目分享
      Sharing: {
        teamId: "",
        projectId: "",
        userId: "",
      },
      dialogTableVisible: false,
      //域名
      httpUrl: "http://api.speaker.netloop.cn/api",
    };
  },
  watch: {
    $route: {
      handler: function (to, form) {
        if (
          this.$route.query.code != undefined &&
          this.$route.query.code != null &&
          this.$route.query.code != ""
        ) {
          this.getWX(this.$route.query.code);
        }
      },
      // 深度观察监听
      deep: true,
      immediate: true,
    },
  },
  created() {
    //分享
    localStorage.setItem("id", this.$route.query.id);
  },
  mounted() {
    this.getWXImge();
  },
  methods: {
    //获取微信二维码
    getWXImge() {
      const obj = new WxLogin({
        id: "qrcode",
        appid: "wx6cf9337df41632f3",
        scope: "snsapi_login",
        redirect_uri: encodeURIComponent("https://speaker.netloop.cn"), //http://speaker.netloop.cn/login
        state: "",
        style: "black",
        //需要把写好的样式用base64转换
        href: "data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDIwMHB4O30NCi5pbXBvd2VyQm94IC50aXRsZSB7ZGlzcGxheTogbm9uZTt9DQouaW1wb3dlckJveCAuaW5mbyB7d2lkdGg6IDIwMHB4O30NCi5zdGF0dXNfaWNvbiB7ZGlzcGxheTogbm9uZX0NCi5pbXBvd2VyQm94IC5zdGF0dXMge3RleHQtYWxpZ246IGNlbnRlcjt9",
      });
    },
    //获取conde
    async getWX(code) {
      try {
        const { data: data } = await this.$http.post("wechat/scan", {
          code,
          state: "GETCODE",
        });
        // console.log(data);
        if (
          data.data.access_token != "" &&
          data.data.access_token != undefined &&
          data.data.access_token != null
        ) {
          localStorage.setItem("token", data.data.access_token);
          this.$message.success(data.message);
          this.$router.replace("/home");
        } else {
          this.open_id = data.data.open_id;
          this.dialogTableVisible = true;
        }
      } catch (error) {
        localStorage.removeItem("code");
      }
    },
    //wx绑定手机号
    async wxbind() {
      const { data: data } = await this.$http.post("wechat/bind", {
        open_id: this.open_id,
        phone: this.inputpo,
        code: this.inputco,
        company_name: this.inputgo,
      });
      localStorage.setItem("token", data.data.access_token);
      this.$message.success(data.message);
      this.$router.replace("/home");
    },
    // 切换登录
    tableswitch(index) {
      this.buttonIndex = index;
    },
    //忘记密码
    getforgetPwd() {
      this.$router.push("/forgetPwd");
    },
    //注册账号
    getRegistered() {
      this.$router.push("/registered");
    },
    //登录提交
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.getLoing();
        } else {
          return false;
        }
      });
    },
    //提交接口
    async getLoing() {
      const { data: data } = await this.$http.post("auth/login", this.ruleForm);
      localStorage.setItem("token", data.data.access_token);
      this.$message.success(data.message);
      console.log(this.$route.query.id);
      if (this.$route.query.id) {
        this.$router.replace({
          path: "/foreignSpeakers",
          query: {
            id: window.localStorage.getItem("id"),
          },
        });
      } else {
        this.$router.replace("/home");
      }
    },
    //跳转详情
    alarm() {},
  },
};
</script>
<style lang="less" scoped>
.dialog-weixin {
  width: 339px;
  height: 39px;
  margin: 0 auto;
}
.dialog-btn-input {
  width: 339px;
  margin: 0 auto;
}
.dialog-logo {
  position: relative;
  right: -305px;
  bottom: 0;
  margin-top: 40px;
  width: 108px;
  height: 35px;
}
.dialog-btn-input-code {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.login {
  width: 100%;
  height: 100%;
  // background: url(../../assets/image/login/bgc.png) no-repeat center;
  .login-text-head {
    color: #3f3f3f;
    font-weight: 600;
    font-size: 30px;
    position: relative;
    top: 5%;
    left: -30%;
  }
  .form-login {
    width: 100%;
    height: 100%;
    padding: 22px 38px;

    .form-login-log {
      width: 228px;
      height: 65px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .form-login-center {
      width: 1245px;
      height: 777px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 10px;
      display: flex;

      // 左侧登录
      .form-login-mask {
        width: 812px;
        height: 777px;
        // background-color: pink;
        img {
          width: 100%;
          height: 100%;
        }
      }

      // 右侧登录
      .form-login-content-box {
        width: 423px;
        // height: 521px;
        // height: 551px;
        height: 565px;
        position: relative;
        left: 10px;
        top: 104px;
        flex: 1;
        background-color: #fff;
        box-shadow: 0px 0px 50px 1px #efefef;
        border-radius: 10px;
        // box-shadow: 1px 1px 30px rgba(0, 0, 0, 0.16);
        .form-login-content-box-head {
          color: #3f3f3f;
          font-weight: 600;
          font-size: 30px;
          margin: 41px 0 38px 47px;
        }
      }
      .form-login-content {
        display: flex;
        flex-direction: column;
        align-items: center;

        .form-login-top-bgc {
          position: absolute;
          top: 0;
          right: 0;
          width: 82px;
          height: 50px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .class-login {
          // margin-top: 100px;
          display: flex;
          .button-list {
            padding: 10px 20px;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            border: 1px solid #dcdfe6;
            font-size: 13px;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            &:hover {
              color: #0e9ba9;
            }
          }
          .button-active {
            background-color: #0e9ba9;
            border: 1px solid #0e9ba9;
            color: #ffffff;
            &:hover {
              color: #fff;
            }
          }
          .button-list:first-child {
            border-radius: 4px 0 0 4px;
          }
          .button-list:last-child {
            border-radius: 0 4px 4px 0;
          }
        }

        .wx-login {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-top: 0;
          #qrcode {
            margin-top: 55px;
          }

          .hashCode-text {
            width: 120px;
            font-size: 13px;
            color: #212529;
            display: flex;
            flex-direction: column;
            align-items: center;
          }
        }
      }
    }
    .account {
      width: 315px;

      .phone-password {
        margin-top: 55px;

        .remember-box {
          text-align: right;
          font-size: 13px;
          color: #0e9ba9;
          cursor: pointer;
          margin-top: 20px;
        }

        .submit {
          margin-top: 55px;
        }
        .add-password {
          margin-top: 40px;
        }
      }
      //修改 ement 样式
      & /deep/ .el-form-item {
        margin-bottom: 5px;
      }
      & /deep/ .el-form-item__label {
        line-height: 0 !important;
      }
      & /deep/ .el-form-item__content .el-input .el-input__inner {
        border-top: 0px;
        border-left: 0px;
        border-right: 0px;
        border-radius: 0px;
        padding: 0;
      }

      .registered {
        text-align: right;
        color: #0e9ba9;
        font-size: 13px;
        margin-top: 15px;
        cursor: pointer;
      }
    }

    .form-login-text {
      position: absolute;
      top: 87.3%;
      left: 50%;
      transform: translateX(-50%);
      font-size: 14px;
      color: #909399;
    }
  }
  .buttom-img {
    position: absolute;
    left: 0;
    bottom: 0;

    img {
      width: 100%;
      height: 100%;
    }
  }
  .pw-text {
    margin-top: 10px;
    margin-bottom: 55px;
    font-size: 12px;
    color: #ccc;
  }
}
</style>
