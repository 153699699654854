<template>
  <!-- 外侧 -->
  <div class="login">
    <div class="form-login">
      <!-- 登录 -->
      <div class="form-login-center">
        <div class="form-login-mask">
          <img src="@/assets/image/login/login.png" />
        </div>
        <div class="form-login-content-box">
          <div class="form-login-content-box-head">修改密码</div>
          <!-- 注册账号密码 -->
          <div class="phone-password">
            <el-form
              :model="ruleForm"
              status-icon
              :rules="rules"
              ref="ruleForm"
              label-width="100px"
              class="demo-ruleForm"
              label-position="top"
            >
              <div class="form-content-flex">
                <!-- 手机号外侧盒子 -->
                <div class="form-content-flex-item">
                  <div class="form-content-flex-item-left">
                    <el-form-item label="手机号" prop="phone">
                      <el-input
                        type="text"
                        v-model="ruleForm.phone"
                        autocomplete="off"
                        clearable
                        placeholder="请输入手机号"
                      ></el-input>
                    </el-form-item>
                  </div>
                </div>
                <!-- 验证码外侧盒子 -->
                <div class="form-content-flex-item">
                  <div class="form-content-flex-item-left">
                    <el-form-item
                      label="验证码"
                      prop="code"
                      class="add-password"
                    >
                      <el-input
                        type="text"
                        v-model="ruleForm.code"
                        autocomplete="off"
                        clearable
                        placeholder="请输入验证码"
                      ></el-input>
                    </el-form-item>
                  </div>
                  <div class="form-content-flex-item-right-btn">
                    <get-code :ruleForm="ruleForm" :type="'PWD'" />
                  </div>
                </div>
                <!-- 密码外侧盒子 -->
                <div class="form-content-flex-item">
                  <div class="form-content-flex-item-left">
                    <el-form-item
                      label="密码"
                      prop="password"
                      class="add-password"
                    >
                      <el-input
                        type="password"
                        v-model="ruleForm.password"
                        autocomplete="off"
                        clearable
                        show-password
                        placeholder="请输入密码"
                      ></el-input>
                    </el-form-item>
                  </div>
                </div>

                <div class="form-content-flex-item">
                  <div class="form-content-flex-item-left">
                    <el-form-item label="确认密码" prop="confirm_pwd">
                      <el-input
                        type="password"
                        v-model="ruleForm.confirm_pwd"
                        autocomplete="off"
                        clearable
                        show-password
                        placeholder="请输入确认密码"
                      ></el-input>
                    </el-form-item>
                  </div>
                </div>
              </div>
              <!-- 登录 -->
              <div class="submit">
                <el-button
                  @click="submitForm('ruleForm')"
                  style="background: #0e9ba9; color: #fff; width: 315px"
                  v-prevent="3000"
                  >确定</el-button
                >
              </div>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import getCode from "../../../components/getCode/index.vue";
export default {
  name: "forgetPwd",
  components: {
    getCode,
  },
  data() {
    //手机
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入手机号"));
      } else {
        callback();
      }
    };
    //密码
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        callback();
      }
    };
    //确认密码
    var validatePass3 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    //验证码
    var validatePass4 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入验证码"));
      } else {
        callback();
      }
    };
    return {
      ruleForm: {
        phone: "", //手机号
        password: "", //密码
        confirm_pwd: "", //忘记密码
        code: "", //验证码
      },
      rules: {
        phone: [{ validator: validatePass, trigger: "blur" }],
        password: [{ validator: validatePass2, trigger: "blur" }],
        confirm_pwd: [{ validator: validatePass3, trigger: "blur" }],
        code: [{ validator: validatePass4, trigger: "blur" }],
      },
    };
  },
  mounted() {},
  methods: {
    //提交
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.forgetPwd();
        } else {
          return false;
        }
      });
    },
    //修改
    async forgetPwd() {
      const { data: data } = await this.$http.post(
        "auth/forget",
        this.ruleForm
      );
      this.$message.success(data.message);
      this.$router.push("/login");
    },
  },
};
</script>
<style lang="less" scoped>
.login {
  width: 100%;
  height: 100%;
  // background: url(../../assets/image/login/bgc.png) no-repeat center;
  .login-text-head {
    color: #3f3f3f;
    font-weight: 600;
    font-size: 30px;
    position: relative;
    top: 5%;
    left: -30%;
  }
  .form-login {
    width: 100%;
    height: 100%;
    padding: 22px 38px;

    .form-login-log {
      width: 228px;
      height: 65px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .form-login-center {
      width: 1245px;
      height: 777px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 10px;
      display: flex;

      // 左侧登录
      .form-login-mask {
        width: 812px;
        height: 777px;
        // background-color: pink;
        img {
          width: 100%;
          height: 100%;
        }
      }

      //右侧登录
      .form-login-content-box {
        width: 423px;
        // height: 571px;
        height: 582px;
        position: relative;
        left: 10px;
        top: 104px;
        flex: 1;
        background-color: #fff;
        box-shadow: 0px 0px 50px 1px #efefef;
        border-radius: 10px;
        // box-shadow: 1px 1px 30px rgba(0, 0, 0, 0.16);
        .form-login-content-box-head {
          color: #3f3f3f;
          font-weight: 600;
          font-size: 30px;
          margin: 41px 0 38px 47px;
        }
      }
      .form-login-content {
        display: flex;
        flex-direction: column;
        align-items: center;

        .form-login-top-bgc {
          position: absolute;
          top: 0;
          right: 0;
          width: 82px;
          height: 50px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .class-login {
          // margin-top: 100px;
          display: flex;
          .button-list {
            padding: 10px 20px;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            border: 1px solid #dcdfe6;
            font-size: 13px;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            &:hover {
              color: #0e9ba9;
            }
          }
          .button-active {
            background-color: #0e9ba9;
            border: 1px solid #0e9ba9;
            color: #ffffff;
            &:hover {
              color: #fff;
            }
          }
          .button-list:first-child {
            border-radius: 4px 0 0 4px;
          }
          .button-list:last-child {
            border-radius: 0 4px 4px 0;
          }
        }

        .wx-login {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          #qrcode {
            margin-top: 55px;
          }
          .hashCode {
            width: 175px;
            height: 175px;
            margin: 65px 0 18px 0;
            border: 1px solid #dbdbdb;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .hashCode-text {
            width: 120px;
            font-size: 13px;
            color: #212529;
            display: flex;
            flex-direction: column;
            align-items: center;
          }
        }
      }
    }
    .form-login-text {
      position: absolute;
      top: 87.3%;
      left: 50%;
      transform: translateX(-50%);
      font-size: 14px;
      color: #909399;
    }
  }
  .buttom-img {
    position: absolute;
    left: 0;
    bottom: 0;
    img {
      width: 100%;
      height: 100%;
    }
  }
}

.phone-password {
  position: relative;
  margin-top: 55px;
  z-index: 9999;

  .form-content-flex {
    // width: 550px;
    padding: 0 47px;
    .form-content-flex-item {
      position: relative;
      width: 100%;
      // width: 800px;
      margin-bottom: 25px;

      display: flex;
      justify-content: space-between;
      align-items: center;

      .form-content-flex-item-left {
        width: 600px;
      }
      .form-content-flex-item-right {
        width: 600px;
      }
      .form-content-flex-item-right-btn {
        position: relative;
        top: 10px;
        right: -10px;
      }
    }
  }

  .submit {
    margin-top: 45px;
    text-align: center;
  }
}

& /deep/ .el-form-item {
  margin-bottom: 5px;
}
& /deep/ .el-form-item__label {
  line-height: 0 !important;
}
& /deep/ .el-form-item__content .el-input .el-input__inner {
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-radius: 0px;
  padding: 0;
}
& /deep/ .el-form-item__content {
  line-height: 40px;
}
& /deep/ .el-input__inner {
  height: 40px;
}
</style>
