<template>
  <div class="home-detail">
    <headinformation />
    <div class="ceshi">
      <el-button
        @click="backProject"
        type="primary"
        style="background: #2563eb; color: #fff; border-color: #2563eb"
        >返回</el-button
      >
    </div>
    <ul class="home-detail-ul">
      <li class="home-detail-ul-li">
        <div class="home-detail-ul-li-img">
          <img :src="detailsInfo.photo" />
        </div>
        <div class="home-detail-ul-li-box">
          <div class="home-detail-ul-li-box-name">{{ detailsInfo.name }}</div>
          <div class="home-detail-ul-li-box-text">
            <div>
              城市/国家：<span class="home-detail-ul-li-box-text-name">{{
                detailsInfo.country
              }}</span>
            </div>
            <div>
              时区时差：<span class="home-detail-ul-li-box-text-name">{{
                detailsInfo.time_zone
              }}</span>
            </div>
            <div>
              医疗领域：<span class="home-detail-ul-li-box-text-name">{{
                detailsInfo.medical_category
              }}</span>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <ul class="home-detail-ul-li-item">
      <li class="home-detail-ul-li-cen">
        <div class="home-detail-ul-li-cen-box">
          <div class="home-detail-ul-li-cen-h">就职机构及职位</div>
          <div class="home-detail-ul-li-cen-text">
            <div v-if="!detailsInfo.institution">暂无数据</div>
            <div v-else>
              {{ detailsInfo.institution }}
            </div>
          </div>
        </div>
        <div class="home-detail-ul-li-cen-box">
          <div class="home-detail-ul-li-cen-h">擅长领域</div>
          <div class="home-detail-ul-li-cen-text">
            <div v-if="!detailsInfo.areas_of_expertise">暂无数据</div>
            <div>
              {{ detailsInfo.areas_of_expertise }}
            </div>
          </div>
        </div>

        <div class="home-detail-ul-li-cen-box">
          <div class="home-detail-ul-li-cen-h">会议演讲及题目</div>
          <div class="home-detail-ul-li-cen-text">
            <div v-if="!detailsInfo.presenting_meeting">暂无数据</div>
            <div>
              {{ detailsInfo.presenting_meeting }}
            </div>
          </div>
        </div>

        <div class="home-detail-ul-li-cen-box">
          <div class="home-detail-ul-li-cen-h">演讲研究赞助商</div>
          <div class="home-detail-ul-li-cen-text">
            <div v-if="!detailsInfo.research_sponsor">暂无数据</div>
            <div>{{ detailsInfo.research_sponsor }}</div>
          </div>
        </div>

        <div class="home-detail-ul-li-cen-box">
          <div class="home-detail-ul-li-cen-h">学协会及任职</div>
          <div class="home-detail-ul-li-cen-text">
            <div v-if="!detailsInfo.association">暂无数据</div>
            <div>
              {{ detailsInfo.association }}
            </div>
          </div>
        </div>

        <div class="home-detail-ul-li-cen-box">
          <div class="home-detail-ul-li-cen-h">期刊及任职及影响因子</div>
          <div class="home-detail-ul-li-cen-text">
            <div v-if="!detailsInfo.journal_position">暂无数据</div>
            <div>
              {{ detailsInfo.journal_position }}
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import headinformation from "@/components/headinformation/index.vue";
export default {
  name: "homeDetail",
  components: {
    headinformation,
  },
  data() {
    return {
      detailsInfo: {},
    };
  },
  created() {
    this.getDetails(this.$route.query.id);
  },
  mounted() {},
  methods: {
    async getDetails(id) {
      const { data: data } = await this.$http.post(
        "speaker/share/speakerDetail",
        { id }
      );
      this.detailsInfo = data.data;
    },
    //返回
    backProject() {
      this.$router.back();
    },
  },
};
</script>
<style lang="less" scoped>
.home-detail {
  min-height: 100vh;
  background-color: #f2f3f5 !important;

  //button
  /deep/ .el-button {
    font-size: 12px !important;
    padding: 9px 20px !important;
  }
  //返回
  .ceshi {
    padding: 10px 95px;
    padding-top: 0;
    text-align: right;
  }
  //列表
  .home-detail-ul {
    margin: 0 50px;
    .home-detail-ul-li {
      display: flex;
      align-items: center;
      padding: 35px 45px;
      margin-bottom: 10px;
      background-color: #fff;
      .home-detail-ul-li-img {
        width: 88px;
        height: 88px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 27px;
        border: 1px solid #d5d5d5;
      }
      .home-detail-ul-li-box {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .home-detail-ul-li-box-name {
          color: #212529;
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 53px;
        }
        .home-detail-ul-li-box-text {
          display: flex;
          align-items: center;

          .home-detail-ul-li-box-text-name {
            color: #c7c7c7;
            margin-right: 100px;
          }
        }
      }
    }
  }

  //列表
  .home-detail-ul-li-item {
    margin: 0 50px;
    background-color: #fff;
    .home-detail-ul-li-cen {
      padding: 26px 42px;

      .home-detail-ul-li-cen-box {
        border: 1px solid #dadee6;
        padding: 17px 32px;
        border-radius: 4px;
        margin-bottom: 30px;
        .home-detail-ul-li-cen-h {
          color: #212529;
          font-size: 15px;
          margin-bottom: 24px;
        }
        .home-detail-ul-li-cen-text {
          color: #777777;
          line-height: 2;
        }
      }
    }
  }
}
</style>
